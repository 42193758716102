<template>
    <div class="categories-wrapper container text-center">
        <div class="row sub-homes">
            <div class="col-12 col-lg-3 my-4" @mouseenter="isStandardActive = true" @mouseleave="isStandardActive = false">
                <router-link class="category-h column-1 d-inline-block w-100" to="/services/homes-standard">
                <transition name="sub-homes-slide" type="animation" mode="out-in" appear>
                    <span class="caption caption-md" v-show="isStandardActive">
                    <p class="p-4 text-center text-white">Правилният избор за сезонно почистване на дома и за освежаване от натрупаните замърсявания. 
                    </p>
                    </span>
                </transition>
                <span class="title">Стандартно</span>
                </router-link>
            </div>
            <div class="col-12 col-lg-3 my-4" @mouseenter="isFineActive = true" @mouseleave="isFineActive = false">
                <router-link class="category-h column-2 d-inline-block w-100" to="/services/homes-fine">
                <transition name="sub-homes-slide" type="animation" mode="out-in" appear>
                    <span class="caption caption-md" v-show="isFineActive">
                    <p class="p-4 text-center text-white">Услугата фино  почистване на домове  - за най-трудно достижимите места със специални технически пособия. 
                    </p>
                    </span>
                </transition>
                <span class="title">Фино</span>
                </router-link>
            </div>
            <div class="col-12 col-lg-3 my-4" @mouseenter="isHealthyActive = true" @mouseleave="isHealthyActive = false">
                <router-link class="category-h column-3 d-inline-block w-100" to="/services/homes-healthy">
                <transition name="sub-homes-slide" type="animation" mode="out-in" appear>
                    <span class="caption caption-md" v-show="isHealthyActive">
                    <p class="p-4 text-center text-white">С екологични и хипоалергенни препарати почистваме домове на алергични хора и тези, в които живеят малки деца.
                    </p>
                    </span>
                </transition>
                <span class="title">Здравословно</span>
                </router-link>
            </div>
            <div class="col-12 col-lg-3 my-4" @mouseenter="isAfterConstActive = true" @mouseleave="isAfterConstActive = false">
                <router-link class="category-h column-4 d-inline-block w-100" to="/services/homes-after-construction">
                <transition name="sub-homes-slide" type="animation" mode="out-in" appear>
                    <span class="caption caption-md" v-show="isAfterConstActive">
                    <p class="p-4 text-center text-white">Разновидност на стандартното почистване, целяща да се премахнат остатъчни строителни отпадъци.
                    </p>
                    </span>
                </transition>
                <span class="title">След ремонт</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SubHomes",
        data() {
        return {
          isStandardActive: false,
          isFineActive: false,
          isHealthyActive: false,
          isAfterConstActive: false,
        }
      }
    }
</script>