<template>
    <div class="offices-bagdes container mt-5">
            <div class="row">
                <div class="col mb-5 mb-md-0">
                    <span class="d-flex">
                        <span class="d-block">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100px" height="100px" x="0px" y="0px"
                            viewBox="0 0 438.891 438.891" style="enable-background:new 0 0 438.891 438.891;" xml:space="preserve">
                                <path style="fill:#666666;" d="M347.968,57.503h-39.706V39.74c0-5.747-6.269-8.359-12.016-8.359h-30.824c-7.314-20.898-25.6-31.347-46.498-31.347
                                    c-20.668-0.777-39.467,11.896-46.498,31.347h-30.302c-5.747,0-11.494,2.612-11.494,8.359v17.763H90.923
                                    c-23.53,0.251-42.78,18.813-43.886,42.318v299.363c0,22.988,20.898,39.706,43.886,39.706h257.045
                                    c22.988,0,43.886-16.718,43.886-39.706V99.822C390.748,76.316,371.498,57.754,347.968,57.503z M151.527,52.279h28.735
                                    c5.016-0.612,9.045-4.428,9.927-9.404c3.094-13.474,14.915-23.146,28.735-23.51c13.692,0.415,25.335,10.117,28.212,23.51
                                    c0.937,5.148,5.232,9.013,10.449,9.404h29.78v41.796H151.527V52.279z M370.956,399.185c0,11.494-11.494,18.808-22.988,18.808
                                    H90.923c-11.494,0-22.988-7.314-22.988-18.808V99.822c1.066-11.964,10.978-21.201,22.988-21.42h39.706v26.645
                                    c0.552,5.854,5.622,10.233,11.494,9.927h154.122c5.98,0.327,11.209-3.992,12.016-9.927V78.401h39.706
                                    c12.009,0.22,21.922,9.456,22.988,21.42V399.185z"/>
                                <path style="fill:#666666;" d="M179.217,233.569c-3.919-4.131-10.425-4.364-14.629-0.522l-33.437,31.869l-14.106-14.629
                                    c-3.919-4.131-10.425-4.363-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                                    c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                                    C179.628,233.962,179.427,233.761,179.217,233.569z"/>
                                <path style="fill:#666666;" d="M329.16,256.034H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                                    c5.771,0,10.449-4.678,10.449-10.449S334.931,256.034,329.16,256.034z"/>
                                <path style="fill:#666666;" d="M179.217,149.977c-3.919-4.131-10.425-4.364-14.629-0.522l-33.437,31.869l-14.106-14.629
                                    c-3.919-4.131-10.425-4.364-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                                    c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                                    C179.628,150.37,179.427,150.169,179.217,149.977z"/>
                                <path style="fill:#666666;" d="M329.16,172.442H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                                    c5.771,0,10.449-4.678,10.449-10.449S334.931,172.442,329.16,172.442z"/>
                                <path style="fill:#666666;" d="M179.217,317.16c-3.919-4.131-10.425-4.363-14.629-0.522l-33.437,31.869l-14.106-14.629
                                    c-3.919-4.131-10.425-4.363-14.629-0.522c-4.047,4.24-4.047,10.911,0,15.151l21.42,21.943c1.854,2.076,4.532,3.224,7.314,3.135
                                    c2.756-0.039,5.385-1.166,7.314-3.135l40.751-38.661c4.04-3.706,4.31-9.986,0.603-14.025
                                    C179.628,317.554,179.427,317.353,179.217,317.16z"/>
                                <path style="fill:#666666;" d="M329.16,339.626H208.997c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449H329.16
                                    c5.771,0,10.449-4.678,10.449-10.449S334.931,339.626,329.16,339.626z"/>
                            </svg>
                        </span>
                        <span class="d-block pl-3 pt-3">
                            <span class="d-block badges-title">Събираме информация</span>
                            <span class="text-small d-block">(за квадратура, брой помещения и др.)</span>
                        </span>
                    </span>
                </div>
                <div class="col mb-5 mb-md-0">
                    <span class="d-flex">
                        <span class="d-block">
                            <svg id="Layer_1" enable-background="new 0 0 512 512" height="100" viewBox="0 0 512 512" width="100" xmlns="http://www.w3.org/2000/svg">
                                    <path style="fill:#666666;" d="m256 512c-60.615 0-119.406-21.564-165.543-60.721-10.833-9.188-20.995-19.375-30.201-30.275-38.859-46.06-60.256-104.657-60.256-165.004 0-68.381 26.628-132.668 74.98-181.02s112.639-74.98 181.02-74.98 132.668 26.628 181.02 74.98 74.98 112.639 74.98 181.02c0 60.348-21.397 118.945-60.251 164.998-9.211 10.906-19.373 21.093-30.209 30.284-46.134 39.154-104.925 60.718-165.54 60.718zm0-480c-123.514 0-224 100.486-224 224 0 52.805 18.719 104.074 52.709 144.363 8.06 9.543 16.961 18.466 26.451 26.516 40.364 34.256 91.801 53.121 144.84 53.121s104.476-18.865 144.837-53.119c9.493-8.052 18.394-16.976 26.459-26.525 33.985-40.281 52.704-91.55 52.704-144.356 0-123.514-100.486-224-224-224z"/>
                                    <path style="fill:#666666;" d="m256 256c-52.935 0-96-43.065-96-96s43.065-96 96-96 96 43.065 96 96-43.065 96-96 96zm0-160c-35.29 0-64 28.71-64 64s28.71 64 64 64 64-28.71 64-64-28.71-64-64-64z"/>
                                    <path style="fill:#666666;" d="m411.202 455.084c-1.29 0-2.6-.157-3.908-.485-8.57-2.151-13.774-10.843-11.623-19.414 2.872-11.443 4.329-23.281 4.329-35.185 0-78.285-63.646-142.866-141.893-143.99l-2.107-.01-2.107.01c-78.247 1.124-141.893 65.705-141.893 143.99 0 11.904 1.457 23.742 4.329 35.185 2.151 8.571-3.053 17.263-11.623 19.414s-17.263-3.052-19.414-11.623c-3.512-13.989-5.292-28.448-5.292-42.976 0-46.578 18.017-90.483 50.732-123.63 32.683-33.114 76.285-51.708 122.774-52.358.075-.001.149-.001.224-.001l2.27-.011 2.27.01c.075 0 .149 0 .224.001 46.489.649 90.091 19.244 122.774 52.358 32.715 33.148 50.732 77.053 50.732 123.631 0 14.528-1.78 28.987-5.292 42.976-1.823 7.262-8.343 12.107-15.506 12.108z"/>
                            </svg>
                        </span>
                        <span class="d-block pl-3 pt-3">
                            <span class="d-block badges-title">Правим оглед</span>
                            <span class="text-small d-block">(при нужда)</span>
                        </span>
                    </span>
                </div>
                <div class="col">
                    <span class="d-flex">
                        <span class="d-block">
                            <svg id="Capa_1" enable-background="new 0 0 512 512" height="100" viewBox="0 0 512 512" width="100" xmlns="http://www.w3.org/2000/svg">
                                <path style="fill:#666666;" d="m94 144h20v20c0 8.284 6.716 15 15 15s15-6.716 15-15v-20h20c8.284 0 15-6.716 15-15s-6.716-15-15-15h-20v-20c0-8.284-6.716-15-15-15s-15 6.716-15 15v20h-20c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/>
                                <path style="fill:#666666;" d="m327 144h60c8.284 0 15-6.716 15-15s-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/>
                                <path style="fill:#666666;" d="m392 367h-70c-8.284 0-15 6.716-15 15s6.716 15 15 15h70c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
                                <path style="fill:#666666;" d="m392 317h-70c-8.284 0-15 6.716-15 15s6.716 15 15 15h70c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
                                <path style="fill:#666666;" d="m164.606 321.394c-5.857-5.858-15.355-5.858-21.213 0l-14.393 14.393-14.394-14.394c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l14.394 14.394-14.394 14.394c-5.858 5.858-5.858 15.355 0 21.213 2.929 2.929 6.768 4.393 10.607 4.393s7.678-1.464 10.606-4.394l14.394-14.393 14.394 14.394c2.928 2.929 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.394c5.858-5.858 5.858-15.355 0-21.213l-14.393-14.393 14.394-14.394c5.858-5.857 5.858-15.355-.001-21.212z"/>
                                <path style="fill:#666666;" d="m486 271.169v-226.169c0-24.813-20.187-45-45-45h-396c-24.813 0-45 20.187-45 45v396c0 24.813 20.187 45 45 45h226.168c24.59 16.416 54.111 26 85.832 26 85.468 0 155-69.533 155-155 0-31.72-9.584-61.241-26-85.831zm-30-226.169v192.83c-26.871-22.361-61.391-35.83-99-35.83s-72.129 13.469-99 35.83v-207.83h183c8.271 0 15 6.729 15 15zm-411-15h183v198h-198v-183c0-8.271 6.729-15 15-15zm-15 411v-183h198v13.169c-16.416 24.59-26 54.111-26 85.831 0 37.609 13.469 72.129 35.83 99h-192.83c-8.271 0-15-6.729-15-15zm327 41c-68.925 0-125-56.075-125-125s56.075-125 125-125 125 56.075 125 125-56.075 125-125 125z"/>
                            </svg>
                        </span>
                        <span class="d-block pl-3 pt-3">
                            <span class="d-block badges-title">Калкулираме най-добрата цена</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
</template>
<script>
    export default {
        name: "Badges"
    }
</script>