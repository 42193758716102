<template>
    <div class="homes-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">ЗА ДОМОВЕ</h4>
                    <p class="text-white mx-5 my-4"><strong>Почистване на домове</strong>: тази услуга се състои в основно <strong>почистване</strong> на всякакъв тип замърсени&nbsp; площи и повърхности в един дом. Поради специфичните желания на всеки клиент, сме изготвили четири пакета от услуги, които да задоволят всичките Ви изисквания.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomesCover",
    }
</script>