<template>
    <div class="homes">
        <SubHomes />
        <HomesCover />
        <div class="container mt-5">
            <h3>За Домове</h3>
            <p>
                Поради спецификата на всяка от дейностите и зоните за почистване, за нас е важно да разберем повече за характерните особености на съответното <strong>жилище</strong>.<br />
		<br />
		<strong>При почистване на домове</strong>, характеристиките, които са важни са: квадратура, брой помещения, вид на помещенията, степен на замърсеност и други. За правилния разчет е необходимо да се информираме за вида на <strong>подовата настилка</strong>, на <strong>мебелите</strong> и други.<br />
		<br />
		Обмислете какво желаете да свършим за вас. При необходимост извършваме оглед. Така можем да дадем коректна информация за организирането на услугата и да калкулираме най-добрата цена за почистване.<br />
		<br />
		Попитайте за нашите услуги като изпратите Вашето запитване онлайн или се свържете с нас на посочените телефони.
            </p>
        </div>
        <Badges />
    </div>
</template>

<script>
    import SubHomes from './homes/SubHomes'
    import HomesCover from "./includes/HomesCover"
    import Badges from './includes/Badges'

    export default {
        name: "Homes",
        components: {
            SubHomes,
            HomesCover,
            Badges,
        }
    }
</script>